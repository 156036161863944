import React, { useState } from 'react'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useLocation } from "@reach/router"
import { Global, css, keyframes } from '@emotion/react'

import Flex from '../../elements/flex'
import Grid from '../../elements/grid'
import Button from '../../elements/button'
import Icon from '../../elements/icon'


import menuIcon from "../../../theme/icons/menu.svg"
import closeIcon from "../../../theme/icons/close.svg"
import contactIcon from "../../../theme/icons/contact-us.svg"
import directionsIcon from "../../../theme/icons/sign-post.svg"
import loginIcon from "../../../theme/icons/login.svg"
import rightArrowIcon from "../../../theme/icons/right-carrot.svg"
import backIcon from "../../../theme/icons/back.svg"



const rootIn = keyframes`
0% {
  transform: translateX(-200%);
}
100% {
  transform: translateX(0%);
}
`

const rootOut = keyframes`
0% {
  transform: translateX(0%);
}
100% {
  transform: translateX(-200%);
`


const t = {
  nav: {
    mobile: {
      background: `linear-gradient( 179.4deg,  rgba(33,150,243,1) 1.8%, rgba(22,255,245,0.90) 97.1% )`,
      filter: `blur(15px)`,
      transition: `0.25s`,
      transitionTimingFunction: `ease-in-out`,
    }
  }
}

const QuickLinkButtons = ({label, icon, to, styles}) => {
  return (
    <Flex className="menuItem">
      <Link to={to} css={{
        display: `block`,
        width: `5.8rem`, 
        paddingTop: `0.55rem`, 
        height: `6rem !important`, 
        border: `1px solid #12a4c2`, 
        borderRadius: `0.75rem`, 
        backgroundColor: `rgba(22, 203, 240, 0.5)`, 
        textAlign: `center`, 
        boxShadow: `0px -1px 5px 1px rgba(7,127,150,0.2)`,
        transition: `all .2s ease-in-out`,
        color: `#000 !important`,
        ...styles,
        '&:hover': {
          //backgroundColor: `#11c0fa`,
          transform: `scale(1.1)`,
          boxShadow: `0px -1px 6px 2px rgba(7,127,150,0.2)`,
        }
      }}>
        <span css={{fontSize: `2.35rem`}}><Icon icon={icon} overrides={{verticalAlign: `-0.3em !important`, width: `1.2em !important`, height: `1.2em !important`}}/></span> <br /> 
        <span css={{fontSize: `0.75rem`}}>{label}</span>
      </Link>
    </Flex>
  )
}


const SecondLevelLink = ({key , to, text}) => (
  <Link 
   key={key}
   to={to}
   activeStyle={{color: `#000 !important`, fontWeight: `600`}}
   css={{
      display: 'block',
      fontSize: `1.25rem`,
      display: `flex`,
      justifyContent: `space-between`,
      margin: `0.75rem 0`,
      cursor: `pointer`,
      textDecoration: `none`,
      color: '#30336b !important',
      transition: `color 0.3s ease-in`,
      '&:hover': {
        color: `#dbfbff !important`,
      }

    }} 
  >
    <span>{text}</span>
    <span css={{fontSize: `1rem`}}><Icon icon={rightArrowIcon} overrides={{verticalAlign: `-0.25em !important`, width: `1.2em !important`, height: `1.2em !important`}}/></span>
  </Link>
)


const MobileNavMenu = ({ data, forceRootMenu }) => {


  const location = useLocation()
  const currentTLP = `/${location.pathname.split('/')[1]}`
  const definedActivePath = forceRootMenu || currentTLP

  const [isNavOpen, setIsNavOpen] = useState(false)
  const [activePath, setActivePath ] = useState(definedActivePath || '/')

  const openNav = () => { setIsNavOpen(true)}
  const clsoeNav = () => { setIsNavOpen(false)}

  // Menu Items Block  
  const menuItems = data.menusSitenavigationmenuYaml.menuItems.map(i => (
    <div key={i.path}>
    <div 
      onClick={() => setActivePath(i.path)}
      css={{
        fontSize: `1.602rem`,
        display: `flex`,
        justifyContent: `space-between`,
        margin: `0.5rem 0`,
        cursor: `pointer`,
        color: `#000`,
        transition: `color 0.3s ease-in`,
        '&:hover': {
          color: `#dbfbff`,
        },
        
      }}
    >
      <span>{i.longText}</span>
      <span css={{fontSize: `1.3rem`}}><Icon icon={rightArrowIcon} overrides={{verticalAlign: `-0.25em !important`, width: `1.2em !important`, height: `1.2em !important`}}/></span>
    </div>
    <div css={{
      position: `absolute`,
      visibility: activePath === i.path ? 'visable' : 'hidden',
      transition: `visibility 0.3s ease-in`,
      top: `0`,
      left: `200%`,
      width: `85vw`
    }}>
      <Button 
        clickHandler={() => setActivePath('/')} 
        styles={{
          padding: `0.25rem 0 0 0`,
          fontSize: `1rem`,
          '&:hover': {
            color: `#dbfbff`
          }
      }}>                      
        <Icon 
          icon={backIcon} 
          overrides={{
            verticalAlign: `-0.32em !important`, 
            width: `1.2em !important`, 
            height: `1.2em !important`
         }}
        />
        <span css={{
            display: `inline-block`, 
            marginLeft: `0.5rem`, 
            fontSize: `0.9rem`
          }}
        >
          Go Back
        </span> 
      </Button>
      <div css={{padding: `1.5rem 0  0 1rem`}}>
      <div css={{
        fontSize: `1.602rem`,
        fontWeight: `600`
      }}>{i.longText}</div>
      <div css={{marginLeft: `0.75rem`}}>
      <SecondLevelLink to={i.path} text="Overview" />
        {i.subMenu.map((s) => {
          return (
            <SecondLevelLink key={s.path} to={s.path} text={s.longText} />
         );
        })}
          <div css={{
            'a': {
              display: 'block',
              padding: `0.4rem 0`,
              color: `#000`,
              textDecoration: `none`,
              '&:hover': {
                color: `#fff`,
              }
            }
          }}>
            <span>_______</span>
            <div css={{marginTop: `1rem`}}><a href="https://theclaxtonbank.csinufund.com/home/newUser">Open an Account</a></div>
            <div><Link to="/personal/digital-banking">Download our Mobile App</Link></div>
            <div><Link to="/login">Log Into Your Account</Link></div>
            <div><Link to="/resources">Forms & Resources</Link></div>
            <div><Link to="/resources">Financial Literacy</Link></div>
            <div><Link to="/about/contact">Contact Us</Link></div>
            <div css={{
              display: activePath === i.path ? 'block' : 'none',
              height: activePath === i.path ? '100px' : '0',
            }}>.</div>
          </div>
        </div>
      </div>
    </div>
   </div>
  ))

  return (
    <> 
      {/* ------ START: Mobile Nav Manu Hamburger Button ----- */}
        <Button clickHandler={openNav} styles={{height: `2.5rem`, display: `block`, '@media (min-width: 1080px)': {display: `none`}}}>
          <span css={{
            display: `inline-block`, 
            marginRight: `0.5rem`, 
            fontSize: `0.75rem`}
          }>
            Menu {isNavOpen}
          </span> 
          <Icon 
            icon={menuIcon} 
            overrides={{
              verticalAlign: `-0.355em !important`, 
              width: `1.2em !important`, 
              height: `1.2em !important`
            }}/>
        </Button>
      {/* ------ END: Mobile Nav Manu Hamburger Button ----- */}


      {/* ------ START: Mobile Nav Menu ------ */ }
        
          <div
            css={{
              display: `block`,
              position: 'fixed',
              top: '0',
              left: '0',
              width: `100vw`,
              height: isNavOpen ? '100vh' : '0',
              visibility: isNavOpen ? 'visible' : 'hidden',
              backgroundImage: t.nav.mobile.background,
              backdropFilter: t.nav.mobile.filter,
              zIndex: `999999999`,
              transition: t.nav.mobile.transition,
              transitionTimingFunction: t.nav.mobile.transitionTimingFunction,
              overflowX: 'hidden',
              overflowY: 'scroll',
              '@media (min-width: 1080px)': {display: `none`},
              '& div': { opacity: isNavOpen ? `100%` : `0`,},
              '.minorLink': {
                height: isNavOpen ? `2rem` : `0`,
                margin: `0.25rem 0`,
              }
            }}
          >
          {isNavOpen && <Global styles={{'body': { overflow: 'hidden' }}}/> }
            <div css={{position: `relative`}}>
              
              {/* ------ START: Mobile Nav Header ------ */ }
                <Grid.FullWidthSection 
                  styles={{
                    boxShadow: `1px 0px 1px 1px #49b8fa`,
                    borderBottom: `1px solid #0b476e`
                  }}
                > 
                  <Grid.Block start="2" span="12">
                    <Flex container justifyContent="space-between" alignItems="center" styles={{height: `4.0rem`}}>
                      <Flex>
                        <Link to="/">
                          <StaticImage src="../../../images/logos/horizontal-logo_small_dark.png" alt="TCB Logo" loading="eager" height={32} />
                        </Link>
                      </Flex>
                      <Flex>
                        <Button 
                          clickHandler={clsoeNav} 
                          styles={{
                            padding: `0.25rem 0 0 0`,
                            fontSize: `0.75rem`,
                            '&:hover': { color: `#dbfbff`}
                          }}>
                            <span css={{
                              display: `inline-block`, 
                              marginRight: `0.5rem`, 
                              fontSize: `0.75rem`
                            }}
                          >
                            Close Menu
                          </span> 
                          <Icon 
                            icon={closeIcon} 
                            overrides={{
                              verticalAlign: `-0.26em !important`, 
                              width: `1.2em !important`, 
                              height: `1.2em !important`
                            }}
                          />
                        </Button>
                      </Flex>
                    </Flex>
                  </Grid.Block>
                </Grid.FullWidthSection>
              {/* ------ END: Mobile Nav Header ------ */ }

              {/* ------ START: Mobile Nav Root Menu ------ */ }
                <Grid.FullWidthSection>
                  <Grid.Block start="2" span="12">
                    <nav>
                      <div id="rootMenu" 
                        className={activePath === '/' ? 'selected' : 'dismiss'}
                        css={{
                          position: `relative`,
                          transform: `translateX(0%)`,
                          marginTop: `3rem`,
                          '&.selected': {
                            animation: `${rootIn} 0.5s forwards`,
                          },
                          '&.dismiss': {
                            animation: `${rootOut} 0.5s forwards`,
                          }
                        }}>
                          <Link 
                            to="/"
                            css={{
                              fontSize: `1.602rem`,
                              display: `flex`,
                              justifyContent: `space-between`,
                              margin: `0.5rem 0`,
                              cursor: `pointer`,
                              color: `#000 !important`,
                              transition: `color 0.3s ease-in`,
                              textDecoration: `none`,
                              '&:hover': {
                                color: `#dbfbff !important`,
                              }, 
                            }}
                          >
                            <span>Home</span>
                            <span css={{fontSize: `1.3rem`}}><Icon icon={rightArrowIcon} overrides={{verticalAlign: `-0.25em !important`, width: `1.2em !important`, height: `1.2em !important`}}/></span>
                          </Link>
                        {menuItems}
                        <div css={{
            'a': {
              display: 'block',
              padding: `0.4rem 0`,
              color: `#000`,
              textDecoration: `none`,
              '&:hover': {
                color: `#dbfbff`,
              }
            }
          }}>
            <div css={{marginTop: `1rem`}}><Link to="/about/locations">Locations & Hours</Link></div>
            <div><Link to="/about/contact">Contact Us</Link></div>
            <div><Link to="/about/careers">Careers</Link></div>

          </div>

                        <Flex container justifyContent="center" styles={{paddingTop: `2rem`, opacity: isNavOpen ? `100%` : `0`, transition: `all 100ms ease-in`,}}>
                          <QuickLinkButtons label="Contact Us" icon={contactIcon} to="/about/contact"/>
                          <QuickLinkButtons label="Directions" icon={directionsIcon} styles={{margin: `0 2rem`}} to="/about/locations"/>
                          <QuickLinkButtons label="Log In" icon={loginIcon} to="/login"/>
                        </Flex>
                      </div>
                    </nav>
                  </Grid.Block>
                </Grid.FullWidthSection>
              {/* ------ END: Mobile Nav Root Menu ------ */ }
            </div>
          </div>

      {/* ------ END: Mobile Nav Menu ------ */ }
    </>
  )
}

export default MobileNavMenu